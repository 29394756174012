.markdown-page {
    max-width: 800px;
    margin: 100px auto;
    padding: 3rem 1.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #fdfdfd;
  }
  
  .markdown-page h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #111;
  }
  
  .markdown-page h2,
  .markdown-page h3 {
    font-size: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.25rem;
  }
  
  .markdown-page p {
    margin-bottom: 1rem;
  }
  
  .markdown-page ul {
    padding-left: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .markdown-page li {
    margin-bottom: 0.5rem;
  }
  
  .markdown-page a {
    color: #007bff;
    text-decoration: none;
  }
  
  .markdown-page a:hover {
    text-decoration: underline;
  }
  
  .markdown-page strong {
    font-weight: 600;
  }

@media screen and (min-width: 600px)  {
  .markdown-page {
    margin: 200px auto;
  }
}
  