/* components/RepCard/RepCard.css */
.rep-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    word-break: break-word;
    padding-bottom: 32px;
}

.rep-card-header h3 {
    margin: 32px;
}

.rep-card-content {
    display: flex;
}

.rep-image-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.rep-image {
    width:100%;
    /* Full width of the container */
    position: relative;
    max-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rep-image img {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the area without distortion */
    /* display: block;
    max-height: 300px; */
    height: 300px;
}

.rep-info {
    padding: 15px;
    word-break: break-word;
}

.rep-info h3 {
    margin-top: 0;
}

.rep-info p {
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.rep-social {
    margin-top: 10px;
}

.rep-social a {
    display: flex;
    padding: 4px;
    margin-right: 10px;
}

@media (max-width: 600px) {
    .rep-image {
        width: 100%;
        height: 300px;
    }
    .rep-card-content {
        flex-direction: column;
    }
}

@media (min-width: 600px) {
    .rep-image {
        padding-top: 0;
        flex: 1;
    }

    .rep-info {
        flex: 1;
    }
}