/* Global reset and font styles */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: sans-serif;
}

/* Container for the entire Home component */
.home-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* HERO SECTION */
.hero {
	position: relative;
	width: 100%;
	min-height: 60vh;
	display: flex;
	align-items: center;
	justify-content: center;
	/* Gradient background as requested */
	background: linear-gradient(to right, #37c6ff, #ffffff, #f17579);
	padding: 2rem 1rem;
	overflow: hidden;
	/* Ensures the hex grid doesn't bleed out */
}

.home-container button {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Position hero text above the hex grid */
.hero-content {
	position: relative;
	z-index: 2;
	text-align: center;
	max-width: 800px;
	align-items: center;
	display: flex;
	flex-direction: column;
}

.hero-content h1 {
	font-size: 3rem;
	margin-bottom: 1rem;
	letter-spacing: 2px;
}

.hero-content p {
	font-size: 1.2rem;
	margin-bottom: 2rem;
	line-height: 1.6;
}

.cta-button {
	padding: 0.75rem 1.5rem;
	font-size: 1rem;
	border: none;
	background: #333;
	color: #fff;
	cursor: pointer;
	transition: background 0.3s ease;
}

.cta-button:hover {
	background: #555;
}

/* HEX GRID OVERLAY */
.hexgrid-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	/* behind hero-content */
	pointer-events: none;
	/* pass clicks through so hero button is clickable */
	display: flex;
	align-items: center;
	justify-content: center;
}

.hex-cell polygon {
	fill: rgba(255, 255, 255, 0.15);
	stroke: none;
	transition: fill 0.3s ease;
	pointer-events: auto;
	/* allow hover effect */
}

.hex-cell:hover polygon {
	fill: rgba(255, 255, 255, 0.35);
}

/* HIGHLIGHTS SECTION */
.highlights {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1rem;
	padding: 3rem 1rem;
	max-width: 1200px;
	width: 100%;
}

.highlight-card {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	flex: 1 1 300px;
	margin: 0.5rem;
	max-width: 350px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.highlight-card img {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.highlight-card h2 {
	font-size: 1.5rem;
	margin: 1rem;
}

.highlight-card p {
	font-size: 1rem;
	margin: 0 1rem 1.5rem;
	color: #666;
	line-height: 1.5;
}

/* INFO SECTION */
.info-section {
	max-width: 800px;
	text-align: center;
	padding: 2rem 1rem;
}

.info-section h2 {
	font-size: 2rem;
	margin-bottom: 1rem;
}

.info-section p {
	font-size: 1.1rem;
	color: #444;
	line-height: 1.6;
	margin-bottom: 1.5rem;
}

.info-section .submit-button {
display: inline-flex;
margin: 0 auto;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
	.hero {
		min-height: 94vh;
	}

	.hero-content h1 {
		font-size: 2.2rem;
	}

	.hero-content p {
		font-size: 1rem;
	}

	.highlight-card h2 {
		font-size: 1.3rem;
	}
}

@media (max-width: 480px) {
	.hero-content h1 {
		font-size: 1.8rem;
	}

	.hero-content p {
		font-size: 0.9rem;
	}

	.highlight-card h2 {
		font-size: 1.1rem;
	}

	.highlight-card p {
		font-size: 0.9rem;
	}

}