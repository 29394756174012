/* Container for the entire page */
.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    margin: 100px auto;
    padding: 20px;
}

/* Header styling */
.about-page-header {
    margin-bottom: 20px;
}

.about-page-header h1 {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
    color: #333;
}

/* Description area styling */
.about-page-description {
    width: 100%;
    margin-bottom: 40px;
    line-height: 1.6;
    font-size: 1.1rem;
    color: #444;
}

.about-page-description p {
    margin-bottom: 1.2rem;
}

/* FAQs container */
.about-page-faqs {
    width: 100%;
}

/* Individual FAQ item styling */
.faq-item {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px 25px;
    transition: background-color 5s ease;
}

.faq-item:hover {
    cursor: pointer;
    background-color: #fafafa;
}

/* FAQ question row */
.faq-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
}

.faq-question h3 {
    font-size: 1.2rem;
    margin: 0;
    color: #333;
}

.faq-question span {
    font-size: 1.5rem;
    color: #999;
    transition: color 0.2s ease;
}

.faq-question:hover span {
    color: #333;
}

/* FAQ answer area */
.faq-answer {
    margin-top: 10px;
    font-size: 1rem;
    color: #555;
    line-height: 1.4;
}

.faq-links {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
}

.faq-link {
    padding: 6px;
    margin: 4px 0;
}

/* Responsive styling */
@media (max-width: 600px) {

    .about-page {
        margin: 170px auto;
    }
    .about-page-header h1 {
        font-size: 2rem;
    }

    .about-page-description {
        font-size: 1rem;
    }

    .faq-question h3 {
        font-size: 1rem;
    }
}