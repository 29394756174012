.map-page {
    padding: 20px;
}

.map-page h1 {
    padding-top: 40px;
    text-align: center;
}

.tooltip {
    position: absolute;
    z-index: 999;
    background-color: white;
    padding: 4px 8px;
    font-size: 0.85rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    pointer-events: none;
}


.main-content {
    padding: 0;
}

.legend-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

/* Container for the checkboxes */
.layer-toggles {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

/* The legend itself */
.map-legend {
    background-color: #f7f7f7;
    padding: 0.75rem;
    border-radius: 5px;
    min-width: 200px;
}


.legend-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.legend-section {
    margin-bottom: 0.5rem;
}

.legend-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.color-box {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    border: 1px solid #ccc;
}

.citations {
    padding: 40px;
    font-size: 12px;
}

.tooltip {
    padding: 8px;
}

.tooltip-item {
    display: flex;
    align-items: center;
}

.tooltip-item p {
    margin: 4px;
}

.constituent-tooltip-color {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 2px;
}

.election-results {
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    padding: 8px 0;
}

.election-results p {
    margin: 0;
}

.horizontal-divide {
    border-top: 1px solid var(--default-color);
}


.map-description {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.map-page-faqs {
    padding: 5vw;
}