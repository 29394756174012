/* Sidebar.css */
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	width: 250px;
	height: 100%;
	background: #333;
	padding-top: 60px;
	transform: translateX(-250px);
	transition: transform 0.3s ease;
	z-index: 1000;
}

.sidebar.active {
	transform: translateX(0);
}

.sidebar ul {
	list-style: none;
	padding: 0;
}

.sidebar ul li {
	padding: none;
	text-align: center;
}

.sidebar ul li a {
	color: #fff;
	text-decoration: none;
	display: block;
	transition: background-color 0.3s;
	padding: 10px 15px;
}

.sidebar ul li a.active {
	background-color: #555;
}

.sidebar ul li a:hover {
	background-color: #666;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Semi-transparent background */
	z-index: 999;
	/* Less than sidebar's z-index */
	pointer-events: auto;
}