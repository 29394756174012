.vote-card {
    border-radius: 12px;
    box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
    padding: 1em;
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-direction: column;
}

.vote-info {
    display: block;
}


.vote-card-counts .counts-block {
    font-weight: 500;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    padding: 6px 16px;
    margin: 5px;
    border-radius: 6px;
}

.vote-card .rep-votes-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    width: 100%;
    grid-auto-rows: minmax(100px, auto);
}

.vote-card .voter-rep-vote {
    display: flex;
    width: 100%;
}

.vote-card .vote-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.vote-card .rep-image-small {
    display: flex;
    height: 50px;
    width: 50px;
}

.rep-vote-vote {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rep-vote-vote p {
    margin: 1em;
}

.icon-padding-yes {
    background-color: var(--green-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
    /* Adjust to control button size */
}

.icon-padding-no {
    background-color: var(--red-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
    /* Adjust to control button size */
}

@media (min-width: 1024px) {

    .vote-card {
        flex-direction: row;
    }
}

@media (min-width: 600px) {
    .vote-card {
        flex-direction: row;
    }

    .vote-card .vote-card-counts {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 0px) {
    
}