/* src/components/Header/Header.css */
.header {
	display: flex;
	align-items: center;
	background-color: #fff;
	position: fixed;
	width: 100%;
	height: 60px;
	top: 0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	flex-wrap: wrap;
	justify-content: space-between;
	z-index: 998;
}

.header-left {
	padding: 10px 20px;
	display: flex;
	align-items: center;
	flex: 0 0 auto;
}

.hamburger-menu {
	cursor: pointer;
	margin-right: 20px;
}

.hamburger-menu div {
	width: 25px;
	height: 3px;
	background-color: var(--text-color);
	margin: 4px 0;
}

.header h1 {
	font-size: 1.5em;
	color: var(--text-color);
	margin: 0;
	flex: 1 1 auto;
	text-align: center;
}

.zip-code-display {
	display: flex;
	align-items: center;
	flex: 0 0 auto;
}

.zip-code-display span {
	margin: 1em;
	font-size: 1em;
	color: #555;
	display: flex;
}

.zip-code-display span div {
	font-weight: bold;
}

@media (max-width: 480px) {

	.header-left {
		margin: 15px 20px;
	}
	
	.header {
		flex-direction: column;
		align-items: flex-start;
		height: auto;
	}

	.header h1 {
		text-align: left;
	}

	.zip-code-display {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;
	}

	.zip-code-display button {
		padding: 8px 10px;
		font-size: 0.9em;
		margin: 1em;
	}
}