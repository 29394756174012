/* src/pages/ChangeZip/ChangeZip.css */

.change-zip {
margin-top: 100px;
display: flex;
justify-content: center;
align-items: center;
}

.change-zip-container {
background-color: #fff;
padding: 40px 30px;
border-radius: 12px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
width: 100%;
max-width: 400px;
}

.change-zip-container h2 {
text-align: center;
margin-bottom: 20px;
color: var(--text-color);
}

.change-zip-container p {
font-size: 12px;
text-align: center;
}

.change-zip-container form {
display: flex;
flex-direction: column;
}

.change-zip-container label {
margin-bottom: 10px;
font-size: 1em;
color: #555;
}

.change-zip-container input {
padding: 12px 15px;
font-size: 1em;
border: 1px solid #ccc;
border-radius: 8px;
margin-bottom: 20px;
transition: border-color 0.3s;
}

.change-zip-container input:focus {
border-color: var(--primary-color);
outline: none;
}

.change-zip-container button {
	font-size: 1em;
	background-color: var(--dark-color);
	color: #fff;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
}

.change-zip-container button:hover {
	background-color: var(--dark-dark-color);
}

.change-zip-about-link {
text-align: center;
margin-top: 24px;
}

@media (max-width: 600px) {
	.change-zip {
		margin: 170px auto;
	}
	.change-zip-container {
		padding: 30px 20px;
		border-radius: auto;
	}

	.change-zip-container h2 {
		font-size: 1.5em;
	}

	.change-zip-container input,
	.change-zip-container button {
		font-size: 0.9em;
	}
}

@media (max-width: 400px) {
.change-zip-container {
	padding: 20px 15px;
}

.change-zip-container h2 {
	font-size: 1.2em;
}

.change-zip-container input,
.change-zip-container button {
	font-size: 0.85em;
}
}

.submit-button {
display: inline-flex;
align-items: center;
padding: 0.75rem 1.5rem;
font-size: 1rem;
border: none;
background: var(--dark-color);
color: #fff;
cursor: pointer;
transition: background 0.3s ease;
}

.submit-button:hover {
background: var(--primary-dark-color);
}

.arrow-icon {
margin-left: 8px;
transition: transform 0.3s ease;
}

.submit-button:hover .arrow-icon {
transform: translateX(5px);
}	
  