/* index.css */

:root {
  --federal-color: #b4e4e4;
  --state-color: #c8b49d;
  --local-color: #ccccff;
  --default-color: #cccccc;
  --primary-color: #007bff;
  --primary-dark-color: #0056b3;
  --text-color: #333;
  --background-color: #f5f5f5;
  --dark-color: #333;
  --dark-dark-color: #555;

  --green-color: #00e676;
  --red-color: #ff1744;
  --grey-color: #78909c;
}

body {
  margin: 0;
  font-family: futura-pt, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.main-content {
  margin-top: 60px;
  padding: 20px;
  min-height: 100vh;
}


@media screen and (max-width: 768px) {
}

/* Global Input Styles */
input {
  margin: 8px 0;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  width: 100%;
  padding: 12px 15px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* Checkbox Styles */
input[type='checkbox'] {
  width: 18px;
  height: 18px;
  /* appearance: none; */
  /* background-color: var(--background-color); */
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  /* transition: background-color 0.3s, border-color 0.3s; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type='checkbox']:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Date Input Styles */
input[type='date'] {
  width: 100%;
  padding: 10px 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  color: var(--text-color);
  transition: border-color 0.3s, box-shadow 0.3s;
  appearance: none; /* Removes default browser styles */
}

input[type='date']:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

/* Select Input Styles */
select {
  width: 100%;
  padding: 12px 15px;
  margin: 8px 0;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  color: var(--text-color);
  cursor: pointer;
  appearance: none; /* Removes the default dropdown icon for customization */
  transition: border-color 0.3s, box-shadow 0.3s;
}

select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

select::-ms-expand {
  display: none; /* Removes the dropdown arrow for Internet Explorer */
}

/* Additional Mobile Adjustments */
@media (max-width: 480px) {
  input[type='checkbox'],
  input[type='date'],
  select {
    font-size: 0.9em;
  }
}

/* Global Button Styles */
button {
  padding: 0.75rem 1.5rem;
  font-size: 1em;
  background-color: var(--dark-color);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background: var(--primary-dark-color);
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

